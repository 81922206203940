@use "../global" as g;


textarea,select{
    background-color: #fff;
    border: solid 1px #ccc;
}

.contact_block{
	padding: 70px 0 150px;
	max-width: 1080px;
	margin: 0 auto;
	.notice{
		text-align: center;
		margin: 20px auto;
	}
	.comment{
		font-size: 0.9em;
		margin-top: 10px;
	}
}

.contact_block h3{
	text-align: center;
	font-size: 20px;
	padding: 25px 0;
	border-bottom: dotted 1px #B5B5B6;
	border-top: dotted 1px #B5B5B6;
}

.contact_block .lead{
	padding: 25px 0;
	line-height: 1.75em;
	border-bottom: dotted 1px #B5B5B6;
	padding-left: 100px;
}


.contact_block .mw_wp_form dl dt{
	padding-top: 30px;
	width: 280px;
	float: left;
	clear: left;
	font-size: 16px;
	font-weight: 600;
}

.contact_block .mw_wp_form dl dt span{
	font-size: 13px;
	color: #fff;
	background-color: #e75a24;
	padding: 2px 3px;
	border-radius: 2px;
	margin-right: 5px;
	font-weight: normal;
}

.contact_block .mw_wp_form dl dd{
	padding: 30px 0;
	padding-left: 350px;
	border-bottom: dotted 1px #B5B5B6;
	position: relative;
}

.mw_wp_form .error {
    position: absolute;
    bottom: 5px;
    left: 350px;
    line-height: 1.75;
}

.contact_block .mw_wp_form dl dd p{
	margin-bottom: 15px;
	line-height: 1.75;
}

.contact_block .mw_wp_form dl dd p .txt{
	display: block;
	margin-bottom: 3px;
}

.contact_block .mw_wp_form dl dd input[type="text"],
.contact_block .mw_wp_form dl dd input[type="email"]{
	width: 730px;
	padding: 5px 0;
	font-size: 100%;
    background-color: #fff;
    border: solid 1px #ccc;
    border-radius: 10px;
}

.contact_block .mw_wp_form dl dd select{
    padding: 10px 0;
    width: 130px;
    border-radius: 10px;
}

.contact_block .mw_wp_form dl dd textarea{
	width: 730px;
	height: 230px;
	border-radius: 10px;
	border-color: #CCCCCC;
	font-size: 100%;
}

.contact_block .mw_wp_form dl dd .mwform-tel-field input[type="text"]{
	width: 130px;
    border-radius: 10px;
}



.contact_block .mw_wp_form dl dd em{
	font-style: normal;
	display: inline-block;
	width: 100px;
}

.contact_block .mw_wp_form dl .address input[type="text"]{
	width: 340px;
}

.contact_block .mw_wp_form dl .address #zip1,
.contact_block .mw_wp_form dl .address #zip2{
	width: 110px;
}

.contact_block .submitarea{
	width: 340px;
	margin: 0 auto;
}

.confirm_block .submitarea{
	width: 710px;
	margin: 30px auto 0;
}

.contact_block {
    .submit-btn{
        text-align: center;
	display: inline-block;
    &.y_btn{
        margin-top: 30px;
        position: relative;
        z-index: 10;
        border: solid 1px #000;
        border-radius: 35px;
            background-color: #ffe000;
            padding: 15px 45px;
            border-bottom: 5px solid #000;
            -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
            &:hover{
                border-bottom: 2px solid #000;
            }
        
    }
    }
	
}

@media only screen and (max-width: 768px) {


	
		
		.contact_block{
		padding: 12% 0 20%;
		width: 90%;
		margin: 0 auto;
		position: relative;
	}
	
	.contact_block .mw_wp_form dl dt{
		padding-top: 20px;
		width: 100%;
		margin-left: 0px;
		float: none;
		font-size: 16px;
	}
	
	.contact_block .mw_wp_form dl dd{
		padding-bottom: 30px;
		padding-left: 0px;
	}
	
	.mw_wp_form .error {
		position: absolute;
		bottom: 5px;
		left: 0px;
		line-height: 1.75;
		font-size: 75% !important;
	}
	
	.contact_block .mw_wp_form dl dd input[type="text"],
	.contact_block .mw_wp_form dl dd input[type="email"]{
		width: 100%;
	}
	
	.contact_block .mw_wp_form dl dd textarea{
		width: 100%;;
		height: 300px;
	}
	
	.contact_block .mw_wp_form dl dd .mwform-tel-field input[type="text"]{
		width: 28%;
	}
	
	.contact_block .mw_wp_form dl dd .mwform-zip-field input[type="text"]{
		width: 30%;
	}
	
	.contact_block .mw_wp_form dl dd em{
		display: block;
		width: 100%;
		margin-bottom: 5px;
	}
	
	
	.contact_block .submitarea{
		width: 100%;
	}
	
	.confirm_block .submitarea{
		width: 100%;
		margin: 15px auto;
	}
	
	.contact_block .mw_wp_form dl .address input[type="text"]{
		width: 100%;
	}
	
	.contact_block .confirm-btn{
		width: 90%;
	}
	
	.contact_block h3{
		line-height: 1.75em;
	}
	
	.contact_block .lead{
		padding-left: 0px;
	}
	

	
	.contact_block .mw_wp_form dl dd #req_year{
		width: 12%;
	}
	

	

	
	}
