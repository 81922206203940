@use "../global" as g;

body{
  width: 100%;
  font-weight: 500;;
  .pc{
      display: block;
      @include g.mq-down(md) {
          display: none;
      }
  }
  .sp{
      display: none;
      @include g.mq-down(md) {
          display: block;
      }
  }
  .txt{
      line-height: 3.2;
      @include g.mq-down(md) {
         line-height: 2.4;
      }
  }

  .green{
    color: #02aa8f;
  }

  .orange{
    color: #f39800;
  }

  .pink{
    color: #ec6486;
  }

  .npt01 span, .npt02 span {
    display: inline-block;
    width: 79px;
}

.npt01{
  color: #02aa8f;
}

.npt02{
  color: #e56480;
}

.round{
  border-radius: 40px;
}
  
}


.mv{
  width: 100%;
}

header{
  position: relative;
}

.hd_inner{
  width: 100%;
  position: absolute;
  top: 35px;
  z-index:  100;
  &.fixed{
    position: fixed;/*fixedを設定して固定*/
  z-index: 999;
  top:0;
  left:0;
  background-color: #f1f2f2;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
  }
  @include g.mq-down(xl) {
    display: none;
}
  
  .pc_nav{
    

    max-width: 1400px;
    width: 95%;
  margin: 0 auto;
  padding: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 50px;
    @include g.mq-down(xl) {
      display: none;
  }
    .logo{
      max-width: 180px;
      padding-left: 20px;
    }


    .gNav{
      display: flex;
      align-items: center;
      ul{
        display: flex;
        li{
          margin-right: 15px;

          font-size: 0.9em;
          font-weight: 600;
          position: relative;
          a{
            
          position: relative;
          
          }
          .c_btn{
            cursor: pointer;
          }
          .c_menu{
            display: none;
            position: absolute;
            left: -10px;
            top: 30px;
            background-color: #fff;
            border: solid 1px #000;
            border-radius: 10px;
            box-sizing: border-box;
            min-width: 140px;
            &::before{
              content: "";
              display: block;
              width: 25px;
              height: 8.5px;
              background: url(/assets/images/common/fukidashi.png) no-repeat;
              background-size: 100%;
              position: absolute;
              left: 0;
              right: 0;
              top: -8.5px;
              margin: auto;
            }
            li{
              margin: 0 auto;
              padding: 5px 10px;
              border-bottom: dotted 1px #000;
              font-size: 0.9em;
              &:last-child{
                border-bottom: none;
              }
              &::after{
                content: none;
              }
            }

          }
          
        }
      }
      .main_manu{
        li{
          position: relative;
          margin-left: 15px;;
          &::after{
            content: "";
            display: block;
            width: 2px;
            height: 15px;
            background-color: #000;
            margin: 0px auto 0;
            position: absolute;
            left: -15px;
            top: 15%;
          }
        }
      }
      .sub_menu{
        display: flex;
        .rec{
          background-color: #000;
          color: #fff;
          display: inline-block;
          padding: 3px 16px;
          border-radius: 30px;
          font-size: 0.9em;
          margin-right: 15px;
        }
        .sns_menu{
          padding-right: 25px;;
          li{
            display: inline-block;
          }
          .note{
            max-width: 57px;
          }
          .fb{
            max-width: 26px;
          }
        }

      }
    }
  }
}
  .sp_nav{
    display: none;
    @include g.mq-down(xl) {
      display: block;
      .nav_top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        position: absolute;
        top: 0;
        z-index: 1001;
        .logoArea{
          background-color: #fff;
          padding: 15px 20px;
          width: 45%;
          box-sizing: border-box;
          border-bottom-right-radius: 40px;
          box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
          .logo{
            width: 90%;
            line-height: 0;
          }

        }

        .btnArea{
          display: flex;
          margin-right: 5%;
          .contact-btn{
            margin-right: 5px;
            a{
              padding: 2px 10px;
              font-size: 0.8em;
              @include g.mq-down(md) {
                font-size: 3vw;
                }
            }
          }
          .menu-btn{
            padding: 2px 0px;
              font-size: 0.8em;
              background-color: #000;
              color: #fff;
              border: solid 2px #000;
    border-radius: 30px;
    cursor: pointer;
    width: 70px;
    text-align: center;

            @include g.mq-down(md) {
            font-size: 3vw;
            }

        }
        
      
        }
      }
      .sp_nav_cnt{
        display: none;
        width: 100%;
        height: 100vh;
        background-color: #ffe000;
        color: #000;
        position: fixed;
        top: 0;
        z-index: 2002;
        min-height: 700px;
        @include g.mq-down(sm) {
          min-height: 100%;
        }
        .cnt_top{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .logoArea{
            background-color: #fff;
            padding: 15px 20px;
            width: 45%;
            box-sizing: border-box;
            border-bottom-right-radius: 40px;
            box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
            .logo{
              width: 90%;
              line-height: 0;
            }
  
          }
  
          .btnArea{
            display: flex;
            margin-right: 5%;
            .contact-btn{
              margin-right: 5px;
              a{
                padding: 2px 10px;
                font-size: 0.8em;
                @include g.mq-down(md) {
                  font-size: 3vw;
                  }
              }
            }
            .close_btn{
              padding: 2px 0px;
                font-size: 0.8em;
                background-color: #000;
                color: #fff;
                border: solid 2px #000;
      border-radius: 30px;
      font-size: 0.8em;
      cursor: pointer;
    width: 70px;
    text-align: center;
              @include g.mq-down(md) {
              font-size: 3vw;
              }
  
          }
          
        
          }
        }
        .navArea{
          width: 100%;
          height: calc(100vh - 100px);
          overflow: scroll;
          .gNav{
            width: 90%;
            margin: 0 auto;
            background-color: #fff;
            
            ul{
              margin: 50px auto;
              border-bottom: solid 1px #000;
              li{
                border-top: solid 1px #000;
                .c_txt{
                  color: #969696;
                  padding: 15px 5%;
                }
                a{
                  display: block;
                  width: 100%;
                  font-size: 1.2em;
                  font-weight: 600;
                  padding: 10px 0 10px 5%;
                  @include g.mq-down(md) {
                    font-size: 3.7vw;
                  }
                }
                .c_menu{
                  li{
                    a{
                      margin-left: 5%;
                    }
                  }
                  
                }
                &.note{
                  a{
                    display: block;
                    width: 30%;
                    margin-right: 5%;
                    margin-left: auto;
                  }
                }
                &.fb{
                  a{
                    display: block;
                    width: 15%;
                    margin-right: 5%;
                    margin-left: auto;
                  }
                }
  
              }
            }
          }
        }
        
        
      }
  }
  }


.contents{
  .inner{
    width: 1280px;
    margin: 0 auto;
    @include g.mq-down(md) {
        width: 90%;
    }
}
    
  .sec{
    p{
      line-height: 1.8;
    }
      
      .flexbox{
          display: flex;
          @include g.mq-down(md) {
              display: block;
          }
      }
  }

  .lower_top{
    width: 100%;
    height: 450px;
    background: url(/assets/images/common/lower_top.jpg) no-repeat center bottom;
    background-size: cover;
    .lower_ttl{
        text-align: center;
        position: relative;
        top: 160px;
        h1{
            font-size: 1.5em;
            margin-bottom: 20px;
        }
        h2{
            font-size: 0.95em;
        }
    }
}

  
}




.btn{
  text-align: center;
  display: inline-block;
  
  a{
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 5px 35px;
    background-color: #fff;
    border: solid 2px #000;
    border-radius: 30px;
    @include g.mq-down(md) {
      padding: 5px 20px;
      font-size: 3.5vw;
    }
  }
  .arrow{
    display: block;
    width: 12px;
    height: 17px;
    margin-left: 15px;
    margin-top: 2px;
   img{
    display: block;
   }
  }
}