@use "../global" as g;

footer {
  padding-top: 50px;
  .inner{
    width: 1280px;
    margin: 0 auto;
    @include g.mq-down(md) {
        width: 90%;
    }
  }
  .ft_top{
    margin-bottom: -300px;
    @include g.mq-down(md) {
      position: relative;
      margin-bottom: 0px;
      
      &::before{
        content: "";
        display: block;
        width: 100%;
        height: 85%;
        background-color: #ffe000;
        position: absolute;
        bottom: 0;

      }
    }
    .contactArea{
      ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li{
          width: 48%;
          margin-bottom: 20px;
          text-align: center;
          position: relative;
          z-index: 1;
          
          @include g.mq-down(md) {
            width: 100%;
          }
          
          &.kenko_item a{
            background-color: #5ec9b8;
          }
          &.machi_item a{
            background-color: #f18ca5;
          }
          &.koen_item a{
            background-color: #a9a9a9;
          }
          &.rec_item a{
            background-color: #333333;
          }
          /*&::before{
            content: "";
            display: block;
            width: 90%;
            height: 80%;
            border: solid 2px #fff;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin:auto;
            border-radius: 35px;
            position: absolute;
            z-index: -1;
          }*/
          a{
            display: block;
            width: 100%;
            height: 100%;
            padding: 30px 0;
            border-radius: 40px;
            
            border: solid 2px #fff;
          position: relative;
          &::after{
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: #fff;
            bottom: -8px;
            left: 0;
            position: absolute;
            border-radius: 40px;
            z-index: -1;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
          }
          &:hover{
            &::after{
              bottom: -2px;
            }
          }
          
          }
          &.big_item{
            a{
              padding: 70px 0;
            }
            
          }
          .txt{
            color: #fff;
            p{
              line-height: 1.75;
              font-size: 1.4em;
              font-weight: 600;
            }
            .icon{
              width: 75px;
              height: auto;
              margin: 0 auto;
              img{
                display: block;
              }
            }
          }
        }
      }
    }

  }
  .ft_mdl{
    width: 100%;
    background-color: #ffe000;
    padding-top: 400px;
    padding-bottom: 60px;
    position: relative;
    @include g.mq-down(md) {
      padding-top: 60px;
    }
    .ft_img1{
      width: 278px;
      position: absolute;
      right: 0;
      bottom: 100px;
      @include g.mq-down(md) {
        width: 90%;
        bottom: 40%;
      }
    }
    .ft_img2{
      width: 572px;
      position: absolute;
      left: 20%;
      bottom: -50px;
      @include g.mq-down(md) {
        width: 20%;
        left: auto;
        right: 0;
        bottom: 0px;
      }
    }
    .inner{
      display: flex;
      @include g.mq-down(md) {
        display: block;
      }
      .corpArea{
        width: 50%;
        @include g.mq-down(md) {
          width: 100%;
          padding-bottom: 140px;
        }
        .footer_logo{
          max-width: 240px;
          margin-bottom: 10px;;
        }
        .corp_name{
          font-size: 1.2em;
          margin-bottom: 10px;
          font-weight: 600;
        }
        .corp_info{
          font-size: 0.9em;
          margin-bottom: 20px;
        }
        .ft_snsArea{
          display: flex;
            align-items: center;
          li{
            
            margin-right: 10px;
            img{
              display: block;
            }
          }
          .note{
            width: 60px;
          }
          .fb{
            width: 26px;
          }
        }
      }
      .sitemap{
        display: flex;
        

        ul{
          margin-left: 70px;
          font-weight: 600;
          @include g.mq-down(md) {
            margin-left: 0;
            margin-right: 5%;
          }

          li{
            margin-bottom: 15px;
            ol{
              margin-top: 15px;
              text-indent: 1em;
              font-size: 0.9em;
            }
          }

          
        }
      }
    }
  }
  .ft_btm{
    width: 100%;
    background-color: #fff3b2;
    text-align: right;
    .inner{
      padding: 10px 0;
      .btm_link{
        font-size: 0.8em;
      }
    }

  }


}
