@use "../global" as g;

.p-about{
    width: 100%;

}

.contents{
    .lower_top{
        width: 100%;
        height: 450px;
        background: url(/assets/images/common/lower_top.png) no-repeat center bottom;
        background-size: cover;
        @include g.mq-down(md) {
            background: url(/assets/images/common/lower_top_sp.png) no-repeat center bottom;
        background-size: 100%;
        height: 0;
        padding-bottom: 70%;
        }
        .lower_ttl{
            text-align: center;
            position: relative;
            top: 160px;
            font-weight: 600;
            @include g.mq-down(md) {
                top: 70px;
            }
            h1{
                font-size: 1.5em;
                margin-bottom: 15px;
                @include g.mq-down(md) {
                    font-size: 5vw;
                }
            }
            h2{
                font-size: 0.95em;
                @include g.mq-down(md) {
                    font-size: 4vw;
                }
            }
        }
    }

    .bg_c{
        width: 100%;
        background-color: #fffde8;
        padding: 70px 0;
    }

    .bg_w{
        width: 100%;
        background-color: #fff;
        padding: 70px 0;
    }

    .y_line{
        background: linear-gradient(transparent 50%, #ffe000 50%);
        padding: 0 5px;
    }

    .lower_contentArea{
        position: relative;
        padding-bottom: 200px;
        @include g.mq-down(lg) {
            padding-bottom: 50px;
        }
        .sec{
            .inner{
                .cnt_box{
                    max-width: 880px;
            margin-left: 360px;
            margin-right: 0;
            @include g.mq-down(lg) {
                width: 90%;
                margin: 0 auto;
            }
            .lead_ttl{
                font-size: 1.8em;
                font-weight: 600;
                margin-bottom: 40px;
                letter-spacing: 2px;
                @include g.mq-down(md) {
                    font-size: 5.5vw;
                    line-height: 2;
                }
            }

            .sec_ttl{
                font-size: 1.5em;
                line-height: 2;
                margin-bottom: 20px;
                .tag{
                    display: block;
                    width: 79px;
                }
                @include g.mq-down(md) {
                    font-size: 4.5vw;
                    line-height: 2;
                }
            }
            .flexbox{
                justify-content: space-between;
                &.r_flex{
                    flex-direction: row-reverse;
                }
                .txtArea{
                    max-width: 500px;
                    width: 60%;
                    @include g.mq-down(md) {
                        width: 100%;
                        max-width: 100%;
                        margin-bottom: 30px;
                    }
                }
                .imgArea{
                    max-width: 320px;
                    width: 35%;
                    @include g.mq-down(md) {
                        width: 100%;
                        max-width: 100%;
                        margin-bottom: 30px;
                    }
                    .round{
                        display: block;
                        border-radius: 40px;
                    }
                    
                }
            }
            .y_btn{
                margin-top: 50px;
            position: relative;
            z-index: 10;
            a{
                background-color: #ffe000;
                padding: 15px 45px;
  transition: all 0.3s;
                &:hover{
                    border-bottom: 2px solid #000;
                }
                @include g.mq-down(md) {
                    padding: 15px 25px;
                    font-size: 3.5vw;
                }
            }
            }
            .movieArea{
                margin-bottom: 60px;
                width: 100%;
                @include g.mq-down(md) {
                    width: 100%;
                    max-width: 100%;
                }
            }

            .topics{
                background-color: #f2f2f2;
                border-radius: 20px;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                font-size: 0.85em;
                margin: 40px auto;
                line-height: 2;
                a{
                    text-decoration: underline;
                }
            }

            .btn{
                margin-top: 30px;
            }
                }
                
            }
            .cnt_box{
                .news_block{
                    background: #FFF;
                    margin: 40px 0;
                    ul{
                        
                        li{
                            border-bottom: 1px solid #000;
                            padding: 12px 50px;
                            line-height: 1.8;
                            @include g.mq-down(md) {
                                padding: 10px 20px;
                            }
                            .news_title{
                                display: flex;
                                align-items: center;
                                span{
                                    margin: auto 5px;
                                }
                                @include g.mq-down(md) {
                                    flex-wrap: wrap;
                                    a{
                                        display: block;
                                        width: 100%;
                                    }
                                   
                                }
                            }
                            
                            &:first-child{
                                border-top: 1px solid #000;
                            }
                        }
                    }
                }
            }
            .pagerArea{
                text-align: center;
                .pager{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    li{
                        margin: auto 10px;
                        &.num{
                            padding: 11px 20px;
                            border-radius: 50%;
                            background-color: #a9a9a9;
                            color: #fff;
                        }
                        &.current{
                            background-color: #000;
                        }
                        &.next{
                            color: #a9a9a9;
                        }

                    }
                }
            }

            .history_box{
                background-color: #f2f2f2;
                padding: 70px 40px 10px;
                border-radius: 40px;
                box-sizing: border-box;
                dl{
                    .row{
                        display: flex;
                        margin-bottom: 40px;
                        @include g.mq-down(md) {
                            display: block;
                            margin-bottom: 30px;
                        }
                        dt{
                            width: 80px;
                            color: #e75a24;
                            @include g.mq-down(md) {
                                width: 100%;
                            }
                        }
                        dd{
                            width: calc( 100% - 80px);
                            @include g.mq-down(md) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .info_ttl{
                font-weight: 600;
                margin-bottom: 15px;
            }
           .info_box{
            margin-bottom: 30px;
            dl{
                border-top: solid 1px #000;
                background-color: #fff;
                .row{
                    display: flex;
                    padding: 10px 15px;
                    border-bottom: solid 1px #000;
                    @include g.mq-down(md) {
                        display: block;
                    }
                    dt{
                        width: 150px;
                        color: #e75a24;
                        @include g.mq-down(md) {
                            width: 100%;
                            margin-bottom: 5px;
                        }
                    }
                    dd{
                        width: calc( 100% - 150px);
                        @include g.mq-down(md) {
                            width: 100%;
                        }
                    }
                }
            }
           } 
           .mapArea{
            margin: 50px auto;
           }

           .tagArea{
            max-width: 160px;
            margin: 40px auto 80px;
           }
            
        }
        .side_wrap{
            @include g.mq-down(lg) {
                display: none;
            }

            .side_menu{
                position: absolute;
                width: 290px;
                background-color: #fff3b2;
                border: solid 2px #000;
                border-bottom: solid 5px #000;
                border-radius: 30px;
                text-align: center;
                &::before{
                    content: "";
            display: block;
            width: 245px;
            height: 70px;
            background: url(/assets/images/common/index_top.png) no-repeat center top;
            background-size: cover;
            position: absolute;
            left: 20px;
            top: -55px;

                }
                
                .menu_top{
                    display: inline-block;
                    text-align: center;
                    background-color: #000;
                    color: #fff;
                    font-weight: 600;
                    padding: 2px 40px;
                    border-radius: 15px;
                    margin: 25px auto;
                }
                ul{
                    width: 92%;
                    margin: 0 auto 20px;
                    text-align: left;
                    border-top: dashed 1px #a9a9a9;
                    li{
                        padding: 10px 0;
                        border-bottom: dashed 1px #a9a9a9;
                        position: relative;
                        a{
                            position: relative;
                            p{
                                display: inline-block;
                            }
                            em{
                                font-style: normal;
                                margin-right: 5px;
                            }
                            &::before{
                                content: "";
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                background:  url(/assets/images/common/arrow_down.png) no-repeat;
                                background-size: 100%;
                                vertical-align: top;
                                margin-right: 5px;
                                margin-top: 4px;
                            }
                        }
                    }
                }
                .tag{
                    display: inline-block;
                    width: 79px;
                }
                .news_list{
                    li{
                        a{
                            &::before{
                                content: "";
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                color: #fff;
                                background: #000;
                                border-radius: 50%;
                                vertical-align: top;
                                margin-right: 5px;
                                margin-top: 4px;
                                position: relative;
                            }
                            &::after{
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                margin: auto;
                                content: "";
                                vertical-align: middle;
                                                            left: 4px;
                                width: 6px;
                                height: 6px;
                                border-top: 1px solid #fff;
                                border-right: 1px solid #fff;
                                -webkit-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }

                        }
                        &.current{
                            a{
                                color: #e75a24;
                                &::before{
                                    background: #e75a24;
                                }
                            }
                        }
                    }
                }
            }
        }

        .btm_wrap{
            @include g.mq-up(lg) {
                display: none;
            }

            .btm_menu{
                width: 90%;
                max-width: 600px;
                background-color: #fff3b2;
                border: solid 2px #000;
                border-bottom: solid 5px #000;
                border-radius: 30px;
                text-align: center;
                margin: 50px auto;
                position: relative;
                &::before{
                    content: "";
            display: block;
            width: 80%;
            height: 0;
            padding-bottom: 18vw;
            background: url(/assets/images/common/index_top.png) no-repeat center top;
            background-size: 100%;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: -55px;

                }
                .menu_top{
                    display: inline-block;
                    text-align: center;
                    background-color: #000;
                    color: #fff;
                    font-weight: 600;
                    padding: 2px 40px;
                    border-radius: 15px;
                    margin: 25px auto;
                }
                ul{
                    width: 92%;
                    margin: 0 auto 20px;
                    text-align: left;
                    border-top: dashed 1px #a9a9a9;
                    li{
                        padding: 10px 0;
                        border-bottom: dashed 1px #a9a9a9;
                        position: relative;
                        a{
                            position: relative;
                            p{
                                display: inline-block;
                            }
                            em{
                                font-style: normal;
                                margin-right: 5px;
                            }
                            &::before{
                                content: "";
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                background:  url(/assets/images/common/arrow_down.png) no-repeat;
                                background-size: 100%;
                                vertical-align: text-top;
                                margin-right: 5px;
                                margin-top: 4px;
                            }
                        }
                    }
                }
                .tag{
                    display: inline-block;
                    width: 79px;
                }
                .news_list{
                    li{
                        a{
                            &::before{
                                content: "";
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                color: #fff;
                                background: #000;
                                border-radius: 50%;
                                vertical-align: text-top;
                                margin-right: 5px;
                                margin-top: 4px;
                                position: relative;
                            }
                            &::after{
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                margin: auto;
                                content: "";
                                vertical-align: middle;
                                                            left: 4px;
                                width: 6px;
                                height: 6px;
                                border-top: 1px solid #fff;
                                border-right: 1px solid #fff;
                                -webkit-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }

                        }
                        &.current{
                            a{
                                color: #e75a24;
                                &::before{
                                    background: #e75a24;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}
