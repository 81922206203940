@use "../global" as g;

.p-houkatsu{
    width: 100%;
    .lower_contentArea{
        .img_cnt{
            margin: 50px auto 60px;
        }
        .sec_ttl{
            .bg_p{
                display: block;
                box-sizing: border-box;
                background-color: #ec6486;
                color:  #fff;
                padding: 5px 20px;
                border-radius: 20px;
                font-size: 0.95em;
                margin-bottom: 20px;
            }
        }
        hr{
            color: #c2c2c2;
            margin-bottom: 60px;
        }
        .contact_bnr{
            dl{
                display: flex;
                box-sizing: border-box;
                align-items: center;
                justify-content: space-around;
                background-color: #ec6486;
                color:  #fff;
                padding: 10px 20px;
                border-radius: 30px;
                font-size: 0.95em;
                margin-bottom: 20px;
                font-weight: 600;
                @include g.mq-down(md) {
                    display: block;
                }
                dt{
                    font-size: 2em;
                    @include g.mq-down(md) {
                        font-size: 5vw;
                    }
                }
                dd{
                    font-size: 5em;
                    @include g.mq-down(md) {
                        font-size: 10vw;
                    }
                }
            }
        }
    }

}

.p-project{
    .contents{
        .service_sec{
            .service_block1{
                position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    width: 252px;
                    height: 148px;
                    background: url(/assets/images/project/project_img01.png) no-repeat;
                    background-size: 100%;
                    top: -120px;
                    left: 0;
                    @include g.mq-down(md) {
                        width: 189px;
                        height: 111px;
                        left: auto;
                        right: -3%;
                        top: -80px;
                        z-index: 10;
                    }
                }
                
            }

            .service_block2{
                position: relative;
                @include g.mq-down(md) {
                    &::before{
                        content: "";
                            width: 143px;
                        height: 117px;
                        background: url(/assets/images/project/project_img05.png) no-repeat;
                    background-size: 100%;
                    position: absolute;
                    right: -5%;
                    top: 700px;
                    }
                }
                
                    &::after{
                        content: "";
                        position: absolute;
                        width: 510px;
                        height: 362px;
                        background: url(/assets/images/project/project_img03.png) no-repeat;
                        background-size: 100%;
                        bottom: 200px;
                        right: 0;
                        @include g.mq-down(md) {
                            width: 268px;
                        height: 133px;
                        background: url(/assets/images/project/project_img03_sp.png) no-repeat;
                        background-size: 100%;
                        bottom: -150px;
                    }
                }
                
                    
                

            }
            .service_top{
                .r_flex{
                    flex-direction: row-reverse;
                    justify-content: space-around;
                    .service_content{
                        margin-left: 100px;
                        margin-right: 80px;
                        margin-top: 60px;
                        @include g.mq-down(md) {
                            width: 100%;
                            margin: 0 auto;
                        }
                    }
                }
                
        }
        }
            
        .service_sec1{
            position: relative;
            @include g.mq-down(md) {
                &::before{
                    content: "";
                        width: 215px;
                    height: 99px;
                    background: url(/assets/images/project/project_img04.png) no-repeat;
                background-size: 100%;
                position: absolute;
                right: 0;
                bottom: 650px;
                }
            }
            &::after{
                content: "";
                position: absolute;
                width: 602px;
                height: 396px;
                background: url(/assets/images/project/project_img02.png) no-repeat;
                background-size: 100%;
                bottom: 300px;
                right: 0;
                @include g.mq-down(md) {
                        content: "";
                        width: 311px;
                    height: 147px;
                    background: url(/assets/images/project/project_img02_sp.png) no-repeat;
                background-size: 100%;
                position: absolute;
                right: 0;
                bottom: -50px;
                    
                }
            }
            


        }
    }
    
}

.p-school{
    .img_cnt{
        margin: 50px auto 60px;
    }
    .voice_sec{
        .flexbox{
            .voicebox{
                box-sizing: border-box;
                width: 47%;
                border-radius: 20px;
                background-color: #fff3b2;
                padding: 20px;
                box-sizing: border-box;
                @include g.mq-down(md) {
                    width: 100%;
                    margin-bottom: 20px;
                }
                h4{
                    font-size: 1.2em;
                    font-weight: 600;
                    text-align: center;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .lead_ttl{
        .y_line{
            @include g.mq-down(md) {
                font-size: 5vw;
                line-height: 1.5;
        }
    }
    }
}

.contact_bnr{
    a{
        background-color: #ec6486;
                color:  #fff;
                padding: 50px 20px;
                border-radius: 30px;
                display: block;
                width: 100%;
                text-align: center;
                font-size: 2.2em;
                font-weight: 600;
                box-sizing: border-box;
                @include g.mq-down(md) {
                    font-size: 6vw;
                }

    }
}

.p-labo{
    p.big{
        font-size: 1.4em;
        font-weight: 600;
        margin-bottom: 20px;
        @include g.mq-down(md) {
            font-size: 4vw;
        }
    }

    .y_box{
        box-sizing: border-box;
        padding: 40px;
        background-color: #fff3b2;
        border-radius: 40px;
        margin: 50px auto;
        h4{
            font-weight: 600;
            font-size: 1.4em;
            margin-bottom: 15px;
            text-align: center;
        }
        ul{
            width: 90%;
            margin: 0 auto;
            justify-content: space-between;
            li{
                width: 45%;
                @include g.mq-down(md) {
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }

    }

    .service_sec{
        .flexbox{
            justify-content: space-between;
            margin-top: 40px;
            li{
                width: 48%;
                h4{
                    font-size: 1.1em;
                    font-weight: 600;
                    margin: 20px auto 10px;
                    line-height: 1.5;
                }
                @include g.mq-down(md) {
                    width: 100%;
                    margin-bottom: 40px;
                }

            }
        }
    }
    .field_sec{
        .flexbox{
            justify-content: space-between;
            margin-top: 40px;
            li{
                width: 48%;
                h4{
                    font-size: 1.1em;
                    font-weight: 600;
                    margin: 10px auto 10px;
                    line-height: 1.5;
                }
                @include g.mq-down(md) {
                    width: 100%;
                    margin-bottom: 40px;
                }

            }
        }
    }
    
}

.p-zuncha{
    .txtArea{
        @include g.mq-down(md) {
            margin-bottom: 20px;
        }
    }
    .note_sec{
        .flexbox{
            flex-wrap: wrap;
            @include g.mq-down(md) {
                display: flex;
            }
            li{
                width: 32%;
                margin-bottom: 50px;
                @include g.mq-down(md) {
                    width: 48%;
                    margin-bottom: 40px;
                }
            }
        }
    }
    .nukui_logo{
        max-width: 240px;
        margin-bottom: 30px;
    }
}

.p-request{
    .contents{
        .lower_contentArea{
            padding-bottom: 0;
        }
    }
    .reqest_sec{
        
        .inner{
            max-width: 1080px;
        margin: 0 auto;
        }
        .flexbox{
            margin: 30px auto;
            justify-content: space-between;
            .txtArea{
                width: 60%;
                .big{
                    margin-bottom: 20px;
                    font-weight: 600;
                    font-size: 1.3em;
                    span{
                        font-size: 0.7em;
                    }
                }
                @include g.mq-down(md) {
                    width: 100%;
                    margin-bottom: 30px;
                }
            }
            .imgArea{
                max-width: 320px;
                width: 25%;
                @include g.mq-down(md) {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
        .btn_box{
            text-align: center;
        }
        .y_btn{
            margin: 30px auto;
            text-align: center;
            position: relative;
            z-index: 10;
            a{
                background-color: #ffe000;
                padding: 15px 55px;
                -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  &::after{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    bottom: -5px;
    left: 0;
    position: absolute;
    border-radius: 40px;
    z-index: -1;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  &:hover{
    &::after{
      bottom: -1px;
    }
  }
            }
        }
    }
}

.p-recruit{
    .lead_sec{
        .txtArea{
            .big{
                font-size: 1.6em;
                font-weight: 600;
                margin-bottom: 30px;
            }
        }
    }

    .feature_sec{
        .flexbox{
            .img{
                width: 31%;
                @include g.mq-down(md) {
                    width: 100%;
                    margin-bottom: 30px;
                }
            }
        }
    }

    .faq_sec{
        .faq_box{
            dl{
                background-color: #fff;
                border-top: solid 1px #000;
                .row{
                    border-bottom: solid 1px #000;
                    box-sizing: border-box;
                    font-weight: 600;
                    dt{
                        padding: 30px 20px;
                        position: relative;
                        .tgl{
                            position: absolute;
                            right: 20px;
                            font-size: 1.2em;

                        }

                    }
                    dd{
                        padding: 0 20px 30px;
                        display: none;

                    }
                }
            }
        }
    }

}