@use "../global" as g;

.p-morineki{
    hr{
        color: #c2c2c2;
        margin: 60px auto;
    }

    p.big{
        font-size: 1.4em;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .renkri_sec{
        .flexbox{
            li{
                width: 48%;
                h4{
                    font-size: 1.1em;
                    font-weight: 600;
                    margin: 10px auto 10px;
                    line-height: 1.5;
                }
                @include g.mq-down(md) {
                    width: 100%;
                    margin-bottom: 40px;
                }
            }
        }
    }
    .w_box{
        box-sizing: border-box;
        padding: 40px;
        border-radius: 40px;
        background-color: #fff;
        margin: 50px auto;
        h4{
            font-size: 1.1em;
                    font-weight: 600;
                    margin: 20px auto 10px;
                    line-height: 1.5;
        }
        dl{
            .row{
                display: flex;
                margin-bottom: 5px;
                dt{
                    font-weight: 600;
                    margin-right: 30px;
                }
            }
        }
    }
    .box{
        margin-bottom: 50px;
        .flexbox{
            margin: 20px auto;
            li{
                width: 48%;
                @include g.mq-down(md) {
                    width: 100%;
                    margin-bottom: 40px;
                }
            }
        }
    }
    .anbi_box{
        margin-bottom: 40px;
        .img{
            width: 40%;
            @include g.mq-down(md) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
        .txt{
            width: 55%;
            @include g.mq-down(md) {
                width: 100%;
                
            }
            h4{
                font-size: 1.1em;
                        font-weight: 600;
                        margin: 20px auto 10px;
                        line-height: 1.5;
            }
        }
    }

    .partner_sec{
        .shop_logo{
            max-width: 320px;
            margin-bottom: 40px;
        }
        .txtArea{
            margin-bottom: 60px;
            h4{
                font-size: 1.1em;
                        font-weight: 600;
                        margin: 20px auto 10px;
                        line-height: 1.5;
            }
        }
    }

    .mission_sec{
        .flexbox{
            margin: 40px auto;
            li{
                width: 31%;
                h4{
                    font-size: 1.1em;
                            font-weight: 600;
                            margin: 20px auto 10px;
                            line-height: 1.75;
                }
                @include g.mq-down(md) {
                    width: 100%;
                    margin-bottom: 40px;
                }
            }
        }
    }
}