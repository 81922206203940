@use "../global" as g;







.contents{
    .bnr{
        max-width: 417px;
        position: fixed;
        bottom: 5%;
        right: 0;
        z-index: 2000;
        @include g.mq-down(md) {
            width: 90%;
            left: 0;
            margin: auto;
            bottom: 2%;
        }
    }

    .mv{
        position: relative;
        width: 100%;
        min-height: 900px;
        padding-top: 250px;
        padding-bottom: 100px;
        background-color: #f2f2f2;
        @include g.mq-down(md) {
            padding: 120px 0;
            min-height: 200px;
        }
        .inner{
            position: relative;
        }
        .top_img{
            width: 95%;
            position: absolute;
            right: 0;
            left: 0;
            top: 100px;
            margin: auto;
            @include g.mq-down(md) {
                position: relative;
                width: 100%;
                top: -50px;
                
            }
        }
        .txtArea{
            position: relative;
            z-index: 10;
            h1{
                font-size: 2em;
                font-weight: 600;
                margin-bottom: 40px;
                letter-spacing: 2px;
                @include g.mq-down(md) {
                    font-size: 5vw;
                    margin-bottom: 30px;
                }
            }
            p{
                line-height: 1.75;
                @include g.mq-down(md) {
                    line-height: 2;
                }
                span{
                    padding: 1px 8px;
                    border-radius: 5px;
                    margin: 0 2px;
                }
                .kenko{
                    background-color: #f18ca5;
                }
                .machi{
                    background-color: #5ec9b8;
                }
                

            }

            
        }
        .y_btn{
            margin-top: 30px;
            position: relative;
            z-index: 10;
            a{
                background-color: #ffe000;
                padding: 15px 45px;
                border-bottom: 5px solid #000;
                -webkit-transition: all 0.3s;
  transition: all 0.3s;
                &:hover{
                    border-bottom: 2px solid #000;
                }
            }
        }
    }
    
    .sec{
        .inner{
            max-width: 1280px;
            width: 90%;
            margin: 0 auto;
            @include g.mq-down(md) {
                width: 90%;
            }
        }
        .flexbox{
            display: flex;
            @include g.mq-down(md) {
                display: block;
            }
        }
        .sec_ttl{
            font-weight: 600;
            font-size: 1.8em;
        }
    }
    .news_sec{
        width: 100%;
        position: relative;
        background: url(/assets/images/top/y_bg.png) no-repeat center top;
        background-size: cover;
        padding-bottom: 180px;
        &::before{
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 12.5vw;
            background: url(/assets/images/top/yg_top2.png) no-repeat center top;
            background-size: cover;
            position: absolute;
            left: 0;
            top: -12.2vw;
        }
        &::after{
            content: "";
            display: block;
            width: 90%;
            height: 0;
            padding-bottom: 16vw;
            background: url(/assets/images/top/top_tree.png) no-repeat center top;
            background-size: cover;
            position: absolute;
            left: 5%;
            top: -11vw;
            z-index: 100;
            
        }
        @include g.mq-down(md) {
            &::after{
                content: "";
                display: block;
                background: url(/assets/images/top/top_tree_sp.png) no-repeat center top;
                background-size: 100%;
                padding-bottom: 20vw;
                top: -20vw;
            }

            .n_img1{
                width: 25%;
                position: absolute;
                top: 15px;
                right: 5%;
            }
            
        }
        .flexbox{
            justify-content: space-between;
            position: relative;
            z-index: 10;
            top: 50px;

            .txtArea{
                width: 20%;
                margin-left: 20px;
                @include g.mq-down(md) {
                    width: 100%;
                }
                .sec_ttl{
                    position: relative;
                    z-index: 1;
                    margin-bottom: 60px;
                    .news_en{
                        max-width: 264px;
                        position: absolute;
                        left: -20px;
                        z-index: -1;
                        top: -40px;
                        @include g.mq-down(md) {
                            width: 40%;
                        }

                    }
                }
                .news_list{
                    ul{
                        
                        li{
                            margin-bottom: 20px;
                            font-weight: 500;
                            
                            font-size: 0.9em;
                            span{
                                display: inline-block;
                            padding: 3px 15px;
                            background-color: #fff;
                            border: solid 2px #000;
                            border-radius: 30px;
                            
                            }
                            &.current{
                                span{
                                    background-color: #000;
                                color: #fff;
                                }
                                
                            }
                            
                        }
                    }
                }
            }
            .newsArea{
                max-width: 880px;
                width: 75%;
                @include g.mq-down(md) {
                    width: 100%;
                }
                .news_block{
                    background: #FFF;
                    margin: 40px 0;
                    ul{
                        
                        li{
                            border-bottom: 1px solid #000;
                            padding: 12px 50px;
                            line-height: 1.8;
                            @include g.mq-down(md) {
                                padding: 10px 20px;
                            }
                            .news_title{
                                display: flex;
                                align-items: center;
                                margin-bottom: 5px;
                                span{
                                    margin: auto 8px;
                                    img{
                                        display: block;
                                    }
                                }
                                @include g.mq-down(md) {
                                    flex-wrap: wrap;
                                    a{
                                        display: block;
                                        width: 100%;
                                    }
                                   
                                }
                            }
                            
                            &:first-child{
                                border-top: 1px solid #000;
                            }
                        }
                    }
                }
            }
        }

    }

    .purpose_sec{
        padding: 120px 0;
        position: relative;
        @include g.mq-down(md) {
            padding: 70px 0;
        }
        .pu_img1{
            width: 345px;
            position: absolute;
            left: 0;
            top: 150px;
            @include g.mq-down(md) {
                width: 90%;
                top: -70px;
                left: 5%;
            }
        }
        .pu_img2{
            width: 230px;
            position: absolute;
            right: 0;
            top: 150px;
            @include g.mq-down(md) {
                width: 12%;
                top: 50%;
            }
        }
        .pu_img3{
            width: 270px;
            position: absolute;
            right: 80px;
            bottom: 0;
            @include g.mq-down(md) {
                width: 90%;
                right: 5%;
                bottom: -30px;
            }
        }
        .leadArea{             
            text-align: center;
            position: relative;
            z-index: 100;   
                .sec_ttl{
                    line-height: 1.75;
                    .en{
                        max-width: 185px;
                        display: block;
                        margin: 0 auto 20px;
                    }
                    margin-bottom: 70px;
                }
                .txt{
                    margin-bottom: 30px;
                }
                .moreBtn{
                    max-width: 210px;
                }
                @include g.mq-down(md) {
                    width: 100%;
                }
            }
        
    }
    
    .service_sec{
        padding: 120px 0px;
        @include g.mq-down(md) {
            padding: 80px 0 0;
        }
        .inner{
        .sec_ttl{
            line-height: 1.75;
            position: relative;
            z-index: 1;
                    .cnt_en{
                        width: 624px;
                        display: block;
                        position: absolute;
                        left: 25px;
                        top: -30px;
                        z-index: -1;
                        @include g.mq-down(md) {
                            width: 90%;
                        }
                    }
                    margin-bottom: 70px;
                    @include g.mq-down(md) {
                        margin-bottom: 40px;
                    }
        }
        
        
        .service_img{
            position: relative;
            z-index: 1;
            &::before{
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                background-color: #5ec9b8;
                border-radius: 40px;
                position: absolute;
                bottom: -30px;
                right: -40px;
                z-index: -1;
                @include g.mq-down(md) {
                    right: -25px;
                    bottom: -20px;
                }

            }
            img{
                display: block;
                border-radius: 40px;
            }
        }
        .npt_k{
            .service_img{
                &::before{
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #f18ca5;
                }
            }
        }

        .service_top{
            margin-bottom: 80px;
            @include g.mq-down(md) {
                margin-bottom: 50px;
            }
            .flexbox{
                margin-bottom: 120px;
                .service_img{
                    max-width: 600px;
                    width: 50%;
                    position: relative;
                    
                    @include g.mq-down(md) {
                        width: 90%;
                        margin: 0 auto 60px;
                    }
                }
                .service_content{
                    max-width: 440px;
                    width: 45%;
                    margin-left: 120px;
                    @include g.mq-down(md) {
                        width: 100%;
                        margin: 0 auto;
                    }
                    span{
                        width: 79px;
                        display: block;
                        margin-bottom: 5px;
                    }
                    h3{
                        font-size: 1.8em;
                        font-weight: 600;
                        margin-bottom: 30px
                    }
                    .moreBtn{
                        margin-top: 30px;
                    }
                }
            }
            .r_flex{
                flex-direction: row-reverse;
                .service_content{
                    margin-right: 80px;
                    margin-left: 0;
                }
            }
        }

        .service_btm{
            flex-wrap: wrap;
            justify-content: space-between;
            li{
                width: 47%;
                margin-bottom: 70px;
                @include g.mq-down(md) {
                    width: 100%;
                    margin-bottom: 50px;
                }
                .service_img{
                    width: 90%;
                    position: relative;
                    margin-bottom: 60px;
                    @include g.mq-down(md) {
                        width: 90%;
                        margin: 0 auto 60px;
                    }
                }
                .service_content{
                    width: 100%;
                    @include g.mq-down(md) {
                        width: 100%;
                        margin: 0 auto;
                    }
                    span{
                        width: 79px;
                        display: block;
                        margin-bottom: 5px;
                    }
                    h3{
                        font-size: 1.6em;
                        font-weight: 600;
                        margin-bottom: 15px
                    }
                    .moreBtn{
                        margin-top: 30px;
                    }
                }
            }
            
        }

    }
    }
    
    
}



