@use "../global" as g;

//slick default style
.slick-dots {
  //bottom: -30px !important;
}

.slick-dots li {
  margin: 0 !important;

  button:before {
    font-family: sans-serif !important;
    content: "●" !important;
    color: #b3b3b3 !important;
  }

  &.slick-active button:before {
    color: g.$orange !important;
  }
}

.slick-loading .slick-list {
  background: none !important;
}

.slick-prev:before,
.slick-next:before {
  content: "" !important;
  background-image: url(../images/common/btn_slidearrow.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 25px;
  height: 25px;
  display: block;
  opacity: 1 !important;

  @include g.mq-down(lg) {
    width: 30px;
    height: 30px;
  }
}

.slick-prev:before {
  transform: rotate(-180deg);
}

.slick-prev,
.slick-next {
  width: 25px !important;
  height: 25px !important;
  z-index: 1;

  @include g.mq-down(lg) {
    width: 30px !important;
    height: 30px !important;
  }
}

// slick スライドコントロールスタイル

.control-style1 {

  .slick-prev {
    top: calc(100% + 40px);
    left: 0;
  }

  .slick-next {
    top: calc(100% + 40px);
    right: auto;
    left: 34px;
  }

  .slick-dots {
    text-align: left;
    bottom: -50px;
    left: 80px;
  }

}
