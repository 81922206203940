@use "../global" as g;

.p-privacy {
  width: 100%;
  .privacy_sec{
    .inner{
      max-width: 920px;
      margin: 0 auto;
      line-height: 2.3;
      .box{
        margin-bottom: 50px;
        .sec_ttl{
          max-width: 484px;
          margin-bottom: 80px;
          @include g.mq-down(md) {
            width: 70%;
            margin-bottom: 50px;
        }
        }
        p{
          font-size: 0.9em;
        }

      }
    
    .privacy_txt{
      dl{
        dt{
          font-weight: bold;
          margin-bottom: 30px;
        }
        dd{
          font-size: 0.9em;
          margin-bottom: 40px;
          ul{
            padding-left: 20px;
            text-indent: -20px;
          }
          ol{
            padding-left: 20px;
            text-indent: -20px;
          }
          
        }
      }
    }
  }
  }

}
