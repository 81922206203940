@use "../global" as g;



.p-service{
    width: 100%;
    .contentArea{
        .asset_sec{
            margin-bottom: 100px;
            .top_img{
                max-width: 690px;
                margin-right: 0;
                margin-left: auto;
                @include g.mq-down(md) {
                    width: 80%;
                }
            }
            .txtArea{
                .sec_ttl{
                    max-width: 525px;
                    margin-top: -50px;
                    margin-bottom: 50px;
                    @include g.mq-down(lg) {
                        width: 70%;
                        margin-top: 50px;
                    }
                }
                .txt{
                   margin-bottom: 80px; 
                   margin-left: 180px;
                   @include g.mq-down(md) {
                    width: 100%;
                    margin: 0 auto 60px;
                   }
                }
                .img{
                    margin-left: 180px;
                    @include g.mq-down(md) {
                        width: 100%;
                    margin: 0 auto;
                   }
                    
                }
            }
        }
        .advice_sec{
            padding-bottom: 200px;
            @include g.mq-down(md) {
                padding-bottom: 100px;
            }
            .inner{
                position: relative;
                z-index: 1;
            }
            .imgArea{
                max-width: 755px;
                margin-right: 0;
                margin-left: auto;
                @include g.mq-down(md) {
                    width: 90%;
                }
            }
            .txtArea{
                .sec_ttl{
                    max-width: 605px;
                    margin-top: -150px;
                    margin-bottom: 30px;
                    @include g.mq-down(lg) {
                        width: 80%;
                        margin-top: 50px;
                    }
                }
                .txt{
                    max-width: 1098px;
                }
                margin-bottom: 65px;
            }
            .service_list{
                margin: 0 auto;
            }
            .btm_img{
                max-width: 485px;
                position: absolute;
                left: 0;
                bottom: -120px;
                z-index: -1;
                @include g.mq-down(md) {
                    width: 90%;
                    position: relative;
                    bottom: -40px;
                }
            }

        }
        .service_list{
            max-width: 920px;
            h3{
                font-size: 1.3em;
                margin-bottom: 50px;
                @include g.mq-down(md) {
                    font-size: 4vw;
                }
            }
            ul{
                
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                @include g.mq-down(md) {
                    display: block;

                }
                li{
                    max-width: 450px;
                    width: 48%;
                    box-sizing: border-box;
                    padding: 40px 55px;
                    border: solid 5px #157d3f;
                    border-radius: 20px;
                    background-color: #fff;
                    margin: 20px auto;
                    @include g.mq-down(md) {
                        width: 100%;
                        padding: 30px 40px;
                    }

                    h4{
                        text-align: center;
                        font-size: 1.3em;
                        line-height: 2;
                        &::after{
                            content: "";
                            display: block;
                            width: 150px;
                            height: 4px;
                            background-color: #157d3f;
                            margin: 40px auto;
                            @include g.mq-down(md) {
                                margin: 25px auto;
                            }
                        }
                        @include g.mq-down(sm) {
                            font-size: 4.5vw;
                        }
                    }
                    p{
                        font-size: 0.9em;
                    }
                }
            }
            .linkBtn{
                width: 290px;
                margin-top: 50px;
                margin-left: auto;
                margin-right: 0;

            }
        }
        .estate_sec{
            padding-bottom: 120px;
            .inner{
                position: relative;
                z-index: 1;
            }
            .imgArea{
                max-width: 755px;
                margin-right: 0;
                margin-left: auto;
                @include g.mq-down(md) {
                    width: 90%;
                }
            }
            .txtArea{
                .sec_ttl{
                    max-width: 391px;
                    margin-top: -100px;
                    margin-bottom: 50px;
                    @include g.mq-down(md) {
                        width: 60%;
                        margin-top: 50px;
                    }
                }
                .txt{
                    max-width: 1098px;
                }
                margin-bottom: 65px;
            }
            .service_list{
                margin-left: 0;
                @include g.mq-down(md) {
                position: relative;
                ul{
                    padding-top: 120px;
                }
                }
            }
            .mdl_img{
                max-width: 485px;
                position: absolute;
                right: 0;
                top: 700px;
                z-index: -1;
                @include g.mq-down(md) {
                    position: absolute;
                    width: 85%;
                    top: 60px;
                    right: -5%;
                }
            }
        }
        .photovo_sec{
            padding-bottom: 120px;
            @include g.mq-down(md) {
                padding-bottom: 60px;
            }
           
            .inner{
                position: relative;
                z-index: 1;
            }
            .imgArea{
                max-width: 755px;
                margin-right: 0;
                margin-left: auto;
                @include g.mq-down(md) {
                    width: 90%;
                }
            }
            .txtArea{
                .sec_ttl{
                    max-width: 391px;
                    margin-top: -100px;
                    margin-bottom: 50px;
                    @include g.mq-down(md) {
                        width: 65%;
                        margin-top: 50px;
                    }
                }
                .txt{
                    max-width: 1098px;
                }
                margin-bottom: 65px;
            }
            .service_list{
                margin: 0 auto;
            }
            .btm_img{
                max-width: 485px;
                position: absolute;
                left: 0;
                bottom: -120px;
                z-index: -1;
                @include g.mq-down(md) {
                    width: 80%;
                   position: relative;
                   left: -5%;
                   bottom: -50px;
                }
            }
        }
    }

}